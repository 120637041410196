import React from 'react';
import Link from 'next/link';
import { AudioFile, FileIcon } from '@/assets/icons';
import { ICourseKnowledge } from '@/interfaces/Course';
import { useRouter } from 'next/router';
import { useGetLectureBySlugQuery } from '@/generated/graphql';
import { SEOconfig } from 'next-seo.config';

interface DetailCourseCardProps {
  data?: ICourseKnowledge;
}

export const DetailCourseCard: React.FC<DetailCourseCardProps> = () => {
  const router = useRouter();
  const lectureSlug = router.asPath.replace('/khoa-hoc/', '');
  const { data: detailLecture } = useGetLectureBySlugQuery({
    variables: {
      where: {
        slug: {
          _eq: lectureSlug,
        },
      },
    },
  });
  const { name, description, start_date, lecture_files } =
    detailLecture?.lecture?.[0] || {};
  const courseStatusEnd = new Date(start_date) < new Date();

  const getFileType = (url: string) => {
    if (!url) return null;
    const parts = url.split('.');
    return parts.length > 1 ? parts.pop() : null;
  };

  return (
    <div className="flex w-full flex-col">
      <SEOconfig
        title={`${name} | Tạp chí Tradding`}
        description={description}
      />
      <div className="flex flex-col md:max-w-[664px]">
        <div className="flex flex-col">
          <span className="text-PC-Display-H4 font-header text-[#06101A]">
            {name || 'Tên khóa học'}
          </span>
        </div>
        <div
          className={`text-PC-Caption-Regular border-radius-[2px] my-2 !w-fit rounded-[2px] px-2 py-1 
              ${
                courseStatusEnd
                  ? 'bg-neutral-grayLight text-primary-easternDark'
                  : 'bg-secondary-greenLight text-secondary-greenDark'
              }
            `}
        >
          {courseStatusEnd ? 'Đã kết thúc' : 'Sắp diễn ra'}
        </div>
        <div className="flex flex-col">
          <div
            dangerouslySetInnerHTML={{
              __html: description || '',
            }}
          />
        </div>

        {lecture_files && lecture_files?.length > 0 && (
          <div className="flex flex-col pt-4">
            <span className="text-PC-Title-Regular font-sans text-[#020509]">
              Tài liệu
            </span>
            {lecture_files.map(item => {
              const fileType = getFileType(item?.url);
              return (
                <div
                  key={item?.id}
                  className="flex flex-row items-center gap-2 py-2"
                >
                  {fileType === 'pdf' ? <FileIcon /> : <AudioFile />}
                  <Link
                    href={item?.url}
                    className="text-PC-Body-Regular text-primary-easternDark border-primary-easternDark border-b"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item?.name}
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
