import LazyImage from '../shared/LazyImage';
import { RightIcon } from '@/assets/icons';
import { UrlLink } from '@/configs/urlLink';
import { useRouter } from 'next/router';
import { useGetLectureBySlugQuery } from '@/generated/graphql';
import { format, parseISO } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';

interface CourseInformationCardProps {
  setShowModal?: Dispatch<SetStateAction<boolean>>;
}
const CourseInformationCard: React.FC<CourseInformationCardProps> = ({
  setShowModal,
}) => {
  const router = useRouter();
  const lectureSlug = router.asPath.replace('/khoa-hoc/', '');
  const { data: detailLecture } = useGetLectureBySlugQuery({
    variables: {
      where: {
        slug: {
          _eq: lectureSlug,
        },
      },
    },
  });

  const { name, slug, image } = detailLecture?.lecture?.[0]?.lecturer || {};

  const handleClickDetailAuthor = () => {
    setShowModal && setShowModal(false);
    router.push(`${UrlLink.knowledge.author}/${slug}`);
  };

  return (
    <div className="flex w-full flex-col gap-2 md:w-[240px] lg:w-[320px]">
      <div className="bg-secondary-blueLight flex w-full flex-row items-center justify-between gap-4 rounded-[6px] p-3 md:bg-[#F8F8F8] lg:flex-row">
        <div className="flex flex-row gap-4">
          <div className="bg-primary relative h-[48px] w-[48px] rounded-[50%]">
            <LazyImage
              src={image}
              alt="Thumbnail course"
              fill
              sizes="(max-width: 768px) 100vw, (max-width: 768px) 50vw, 25vw"
              className="object-fill"
              style={{ borderRadius: '50%' }}
            />
          </div>
          <div className="mr-2 flex flex-col">
            <span className=" text-PC-Title-Regular text-neutral-grayDark font-sans">
              Giảng viên
            </span>
            <span className="text-PC-Title-Medium text-primary-easternDark font-sans">
              {name}
            </span>
          </div>
        </div>
        <div onClick={handleClickDetailAuthor}>
          <RightIcon />
        </div>
      </div>

      <div className="flex w-full flex-col gap-4 rounded-[6px] bg-[#F8F8F8] p-3">
        <div className="flex flex-col">
          <span className="text-PC-Title-Regular text-neutral-grayDark font-sans">
            Thời gian
          </span>
          <span className="text-PC-Title-Medium text-neutral-dark font-sans">
            {detailLecture?.lecture?.[0]?.start_date &&
              format(
                parseISO(detailLecture?.lecture?.[0]?.start_date),
                'HH:mm dd/MM/yyyy',
              )}
          </span>
        </div>

        <div className="flex flex-col">
          <span className="text-PC-Title-Regular text-neutral-grayDark font-sans">
            Hình thức
          </span>
          <span className="text-PC-Title-Medium text-neutral-dark font-sans">
            Online - Zoom
          </span>
        </div>
      </div>
    </div>
  );
};
export default CourseInformationCard;
