import React, {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useRef,
} from 'react';
import { CloseButton } from '@/assets/icons';
import LazyImage from '../shared/LazyImage';
import img_url from '@/assets/images/ThanhYouNews.png';

export interface ICommentExchangeModalProps {
  exchangeId: string;
  setShowModel: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ThankYouModelProps {
  setShowSuccessMessage: Dispatch<SetStateAction<boolean>>;
}
const ThankYouNews: React.FC<ThankYouModelProps> = ({
  setShowSuccessMessage,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const handleClickOutside: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setShowSuccessMessage(false);
      }
    },
    [setShowSuccessMessage],
  );

  return (
    <div
      onClick={handleClickOutside}
      className="fixed inset-0 z-50 flex justify-center bg-[#00000080]"
    >
      <div
        ref={modalRef}
        className="mx-2 mt-[50px] flex h-fit w-full flex-col gap-4 rounded-xl bg-white p-12 md:inset-x-auto md:top-[50px] md:mx-0 lg:w-[50%]"
      >
        <div className="flex flex-col">
          <div className="flex justify-end">
            <CloseButton
              className="cursor-pointer"
              onClick={() => setShowSuccessMessage(false)}
            />
          </div>
          <div className="flex justify-center">
            <div className="relative h-[300px] w-[300px]">
              <LazyImage
                className="rounded-lg object-cover"
                src={img_url}
                alt="thank-you"
                fill
                sizes="(max-width: 640px) 50vw, (max-width: 768px) 70vw, 40vw"
              />
            </div>
          </div>
          <div>
            <p className="text-neutral-background-dark font-header md:text-[32px] md:font-bold md:leading-[44.8px]">
              Yêu cầu thành công
            </p>
            <p className="text-neutral-background-dark font-sans md:text-[20px] md:leading-[32px]">
              Bạn sẽ nhận được các tin tức thị trường mới nhất từ chúng tôi. Xin
              chân thành cảm ơn.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ThankYouNews;
