import {
  useGetLectureBySlugQuery,
  useInsert_Course_Speaker_RegistrationMutation,
} from '@/generated/graphql';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from '@/components/shared/AppInput';
import ThankYouNews from '../News/ThankYouNews';
import CourseSharing from './CourseSharing';

export function RegisterCourse() {
  const router = useRouter();
  const url = router.asPath;
  const lectureSlug = router.asPath.replace('/khoa-hoc/', '');
  const { data: detailLecture } = useGetLectureBySlugQuery({
    variables: {
      where: {
        slug: {
          _eq: lectureSlug,
        },
      },
    },
  });
  const { id: lecture_id, lecturer_id } = detailLecture?.lecture?.[0] || {};
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [insertContactFormMutation, { loading }] =
    useInsert_Course_Speaker_RegistrationMutation({
      onCompleted: () => {
        setShowSuccessMessage(true);
      },
    });

  const formik = useFormik({
    initialValues: { name: '', email: '', phone: '' },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required('Vui lòng nhập địa chỉ email!')
        .email('Email không hợp lệ!'),
      phone: Yup.string()
        .required('Vui lòng nhập số điện thoại!')
        .matches(/^(?:\+84|0)(?:\d{9,10})$/, 'Số điện thoại không hợp lệ!'),
    }),
    onSubmit: async values => {
      const { email, name, phone } = values;
      await insertContactFormMutation({
        variables: {
          objects: {
            contact_email: email,
            contact_name: name,
            contact_phone: phone,
            contact_type: 'register',
            lecture_id: lecture_id,
            lecturer_id: lecturer_id,
            content: detailLecture?.lecture?.[0]?.name,
            page_url: `${process.env.NEXT_PUBLIC_DOMAIN}${url}`,
            status: 'progress',
          },
        },
      });
      formik.resetForm();
    },
  });

  return (
    <div className="bg-gray-variants-3">
      <form onSubmit={formik.handleSubmit}>
        <div className="border-neutral-grayLight flex flex-col justify-center rounded-[6px] border-[1px] bg-white px-4 py-6">
          <p className="text-PC-Display-H5 text-primary-easternDark w-full font-sans">
            ĐĂNG KÝ NGAY!
          </p>

          <div className="flex w-full flex-col gap-2 py-2">
            <Input
              label="Họ và tên của bạn"
              value={formik.values.name}
              onChange={formik.handleChange}
              disabled={loading}
              name="name"
            />
            <Input
              label="Email của bạn"
              required
              disabled={loading}
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
            />
            {formik.errors.email && (
              <small className="text-red-400">{formik.errors.email}</small>
            )}
            <Input
              label="Số điện thoại"
              required
              disabled={loading}
              value={formik.values.phone}
              onChange={formik.handleChange}
              name="phone"
            />
            {formik.errors.phone && (
              <small className="text-red-400">{formik.errors.phone}</small>
            )}
          </div>

          <button
            className="text-PC-Button-Medium bg-primary-easternDark hover:bg-primary-eastern my-2 h-[40px] w-full rounded-[4px] px-4 py-2 font-bold text-white"
            type="submit"
            disabled={loading}
          >
            Đăng ký tham gia
          </button>

          <div className="mt-2">
            <span className="text-PC-Title-Regular text-neutral-gray mb-2">
              Chia sẻ khoá học
            </span>
            <CourseSharing />
          </div>
        </div>
      </form>
      {showSuccessMessage && (
        <ThankYouNews setShowSuccessMessage={setShowSuccessMessage} />
      )}
    </div>
  );
}
